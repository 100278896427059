import { format, getTime, formatDistanceToNow, add, startOfMonth, endOfMonth } from 'date-fns';
export function fDate(date, newFormat) {
    var fm = newFormat || 'dd MMM yyyy';
    return date ? format(new Date(date), fm) : '';
}
export function fDateTime(date, newFormat) {
    var fm = newFormat || 'dd MMM yyyy p';
    return date ? format(new Date(date), fm) : '';
}
export function fTimestamp(date) {
    return date ? getTime(new Date(date)) : '';
}
export function fToNow(date) {
    return date
        ? formatDistanceToNow(new Date(date), {
            addSuffix: true,
        })
        : '';
}
export function CurrentDate() {
    return fDate(new Date(), 'yyyy-MM-dd');
}
export function addOneHour(date) {
    return date
        ? add(new Date(date), {
            hours: 1,
        })
        : add(new Date(), {
            hours: 1,
        });
}
export function differentThanCurrent(date, type) {
    var currentDate = new Date();
    switch (type) {
        case 'year':
            return fDate(date, 'yyyy') !== fDate(currentDate, 'yyyy');
        case 'month':
            return fDate(date, 'MM') !== fDate(currentDate, 'MM');
        default:
            return fDate(date, 'dd') !== fDate(currentDate, 'dd');
    }
}
export function getMonthName(date, locale) {
    if (locale === void 0) { locale = 'en-US'; }
    return date.toLocaleDateString(locale, { month: 'long' });
}
export var getStartAndEndOfMonth = function () {
    var now = new Date();
    var dateStartOfMonth = startOfMonth(now);
    var dateEndOfMonth = endOfMonth(now);
    return {
        startOfMonth: dateStartOfMonth,
        endOfMonth: dateEndOfMonth,
    };
};
export function convertMilliseconds(ms, includeTime) {
    if (includeTime === void 0) { includeTime = false; }
    if (ms < 0) {
        return 'Invalid input'; // Handle negative input if needed
    }
    // Define the lengths of different time units in milliseconds
    var msInSecond = 1000;
    var msInMinute = msInSecond * 60;
    var msInHour = msInMinute * 60;
    var msInDay = msInHour * 24;
    var msInWeek = msInDay * 7;
    var msInMonth = msInDay * 30; // Approximate month length
    var msInYear = msInDay * 365; // Approximate year length
    // Calculate years
    var years = Math.floor(ms / msInYear);
    ms %= msInYear;
    // Calculate months
    var months = Math.floor(ms / msInMonth);
    ms %= msInMonth;
    // Calculate weeks
    var weeks = Math.floor(ms / msInWeek);
    ms %= msInWeek;
    // Calculate days
    var days = Math.floor(ms / msInDay);
    ms %= msInDay;
    var hours = 0;
    var minutes = 0;
    var seconds = 0;
    if (includeTime) {
        // Calculate hours
        hours = Math.floor(ms / msInHour);
        ms %= msInHour;
        // Calculate minutes
        minutes = Math.floor(ms / msInMinute);
        ms %= msInMinute;
        // Calculate seconds
        seconds = Math.floor(ms / msInSecond);
    }
    // Construct the result string
    var result = '';
    if (years > 0) {
        result += "".concat(years, " Year").concat(years !== 1 ? 's' : '', " ");
    }
    if (months > 0) {
        result += "".concat(months, " Month").concat(months !== 1 ? 's' : '', " ");
    }
    if (weeks > 0) {
        result += "".concat(weeks, " Week").concat(weeks !== 1 ? 's' : '', " ");
    }
    if (days > 0) {
        result += "".concat(days, " Day").concat(days !== 1 ? 's' : '', " ");
    }
    if (includeTime) {
        if (hours > 0) {
            result += "".concat(hours, " Hour").concat(hours !== 1 ? 's' : '', " ");
        }
        if (minutes > 0) {
            result += "".concat(minutes, " Minute").concat(minutes !== 1 ? 's' : '', " ");
        }
        if (seconds > 0) {
            result += "".concat(seconds, " Second").concat(seconds !== 1 ? 's' : '', " ");
        }
    }
    return result.trim();
}
export function formatLeaveDays(totalLeaveDays) {
    var wholeDays = Math.floor(totalLeaveDays);
    var halfDays = totalLeaveDays - wholeDays;
    var result = '';
    if (wholeDays > 0) {
        result += "".concat(wholeDays, " day").concat(wholeDays > 1 ? 's' : '');
    }
    if (halfDays > 0) {
        if (result.length > 0) {
            result += ' and ';
        }
        result += 'half-day';
    }
    if (result.length === 0) {
        result = 'No leave';
    }
    return result;
}
